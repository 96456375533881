import { AlwaysValid } from '@trustlayer/common';

export * from './ambest-values';
export * from './compliance-profile';
export * from './operators';
export * from './rule';
export const STATE_KEY = 'compliance-profile' as const;
export const namespace = 'COMPLIANCE_PROFILE' as const;

export const SupportedSubjectDateKeys = {
  AlwaysValid,
};

export const SupportedSubjectDateValues = {
  [SupportedSubjectDateKeys.AlwaysValid]: { label: 'Always valid' },
};

export const isSupportedSubjectDateValues = (key: string) =>
  key in SupportedSubjectDateValues;
